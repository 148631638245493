import { LabelPaths, ReplacesList } from "../../../hooks/blocks/util.types"

export const conversationBlocks = {
  conversations: {
    mortgage: {
      details: {
        heading: {
          type: "text",
          description: "Heading at the top of the Mortgage Page for Conversations",
          default: "Your mortgage",
        },
        body: {
          type: "mdx",
          description: `The mortgage details paragraph for active mortgages, that appears at the top of the Mortgage Page for Responsive Conversations. {props.startsOrStarted} will be "starts" if the mortgage hasn't started yet and "started" if it has. {props.startDate} is the day the initial term started.`,
          default: `Your initial rate {props.startsOrStarted} on {props.startDate}. When this ends you move to our standard variable rate (SVR).`,
        },
        svrBody: {
          type: "mdx",
          description: `The mortgage details paragraph for Mortgages on SVR, that appears at the top of the Mortgage Page for Responsive Conversations. {props.expiryDate} is the final day of the preferential term.`,
          default: `You’ve been on our standard variable rate (SVR) since your initial rate ended on {props.expiryDate}.`,
        },
        label: {
          currentRate: {
            type: "text",
            description:
              "The label for the current rate on the Mortgage Page in Conversations",
            default: "Current rate",
          },
          rateEnd: {
            type: "text",
            description:
              "The label for the rate end date on the Mortgage Page in Conversations",
            default: "Rate end",
          },
          variableRate: {
            type: "text",
            description:
              "The label for the variable rate on the Mortgage Page in Conversations",
            default: "Variable rate",
          },
        },
      },
      modal: {
        loanBalance: {
          heading: {
            type: "text",
            description:
              "Heading at the top of the Loan Balance Modal for the Mortgage Page in Conversations",
            default: "Loan balance",
          },
          body: {
            type: "mdx",
            description:
              "The body of the Loan Balance Modal for the Mortgage Page in Conversations. {props.loanBalance} is the loan balance.",
            default: `You have a loan balance of {props.loanBalance}. This is the outstanding amount you have left to pay.`,
          },
        },
        remainingTerm: {
          heading: {
            type: "text",
            description:
              "Heading at the top of the Remaining Term Modal for the Mortgage Page in Conversations",
            default: "Remaining term",
          },
          body: {
            type: "mdx",
            description:
              "The body of the Remaining Term Modal for the Mortgage Page in Conversations. {props.remainingTerm} is the remaining term formatted as [XX years, XX months]. {props.endOfTerm} is the date the full term of the mortgage ends",
            default: `You have a remaining term of {props.remainingTerm} with your mortgage due to end on {props.endOfTerm}.`,
          },
        },
      },
    },
    dashboard: {
      introText: {
        type: "mdx",
        description:
          "The introductory text at the top of the Dashboard page for Conversations. {props.name} is the user's `Nickname` on the Consumer's ESA page (usually their first name, consult the Consumer ESA page for more details on the `Nickname` field)",
        default: `Welcome {props.name}`,
      },
      timeLeft: {
        active: {
          type: "mdx",
          description: `The amount of time left on a mortgage before it expires. Appears on the Dashboard Page and Property Page. {props.time} is the difference in time formatted like "3 months" or "a year". Use {props.capitalizedTime} if it is at the start of a sentence (e.g. "A year"). If the Expiry Date is today then {props.time} will be "less than a day" and {props.capitalizedTime} will be "Less than a day".`,
          default: `{props.capitalizedTime} left on initial term`,
        },
        expired: {
          type: "mdx",
          description: `The amount of time that has elapsed since the mortgage expired. Appears on the Dashboard Page and Property Page. {props.time} is the difference in time formatted like "3 months" or "a year". Use {props.capitalizedTime} if it is at the start of a sentence (e.g. "A year").`,
          default: `Initial term expired {props.time} ago`,
        },
      },
      ctaButton: {
        type: "text",
        description:
          "Text for the 'View property' button on the Dashboard page for Conversations, that leads to the property detail page",
        default: "View property",
      },
      modal: {
        priority: {
          intro: {
            type: "text",
            description:
              "The introductory text at the top of the Dashboard Priority Modal for Conversations, which appears when a user clicks 'View property' on a lower priority property",
            default: "You have an important action on another property.",
          },
          viewButton: {
            type: "text",
            description:
              "The view button in the Dashboard Priority Modal for Conversations, which takes the user to the higher priority property",
            default: "View",
          },
          ignoreButton: {
            type: "text",
            description:
              "The ignore button in the Dashboard Priority Modal for Conversations, which allows the user to continue to the lower priority property",
            default: "Ignore",
          },
        },
        monthlyPayment: {
          heading: {
            type: "text",
            description:
              "Heading at the top of the Dashboard Monthly Payment Modal for Conversations",
            default: "Monthly payment",
          },
          body: {
            type: "mdx",
            description:
              "The body of the Dashboard Monthly Payment Modal for Conversations. {props.monthlyPayment} is the total monthly payment for all properties. {props.propertyCount} is the number of properties.",
            default: `You have a combined monthly payment of {props.monthlyPayment} for your {props.propertyCount} properties.`,
          },
        },
        loanBalance: {
          heading: {
            type: "text",
            description:
              "Heading at the top of the Dashboard Loan Balance Modal for Conversations",
            default: "Loan balance",
          },
          body: {
            type: "mdx",
            description:
              "The body of the Dashboard Loan Balance Modal for Conversations. {props.loanBalance} is the total loan balance for all properties. {props.propertyCount} is the number of properties.",
            default: `You have a combined loan balance of {props.loanBalance} for your {props.propertyCount} properties. This is the outstanding amount you have left to pay.`,
          },
        },
      },
    },
    properties: {
      heading: {
        type: "text",
        description: "Heading at the top of the Properties Page for Conversations",
        default: "Your properties",
      },
    },
    property: {
      heading: {
        type: "text",
        description: "Heading at the top of the Property Page for Conversations",
        default: "Your property",
      },
      mortgageCount: {
        type: "mdx",
        description: `The number of mortgages on the property for Conversations. {props.mortgageCount} is the number of mortgages. {props.mortgagePlural} will be "mortgage" if there is only one mortgage and "mortgages" otherwise.`,
        default: `{props.mortgageCount} {props.mortgagePlural} on your property`,
      },
      action: {
        body: {
          startReview: {
            type: "text",
            description:
              "The accompanying text for the Start review button on the Property Page, used for the EOT Educational conversation to begin the understanding workflow.",
            default:
              "Click below to access key information about moving to a new rate.",
          },
          continueReview: {
            type: "text",
            description:
              "The accompanying text for the Continue review button on the Property Page, used for the EOT Educational conversation to continue the understanding workflow.",
            default: "Click below to continue your review of moving to a new rate.",
          },
          completedLearning: {
            type: "text",
            description:
              "The accompanying text for the See options button on the Property Page, for when a user has completed all learning topics, used for the EOT Educational conversation to begin the intent workflow",
            default:
              "You have completed your current learning topics. Click below to manage your reminders about moving to a new rate.",
          },
          staySVR: {
            type: "text",
            description:
              "The accompanying text for the Get help button, used for the SVR Management conversation if a user chooses to stay on SVR.",
            default:
              "You’ve chosen to stay on SVR. Click below if you'd like to get help.",
          },
          withoutAdvice: {
            noPs: {
              type: "text",
              description:
                "The accompanying text for the Continue review and Get help button on the Property Page, used for the SVR Management conversations if a user selects without advice (no product selection).",
              default:
                "Click 'Continue review' to see rates available to you. Alerts are paused while you action your move.",
            },
            psIncomplete: {
              type: "text",
              description:
                "The accompanying text for the Continue review and Get help button on the Property Page, used for the EOT Transactional conversation if product selection has been started but not finished.",
              default:
                "Click 'Continue review' to see and select rates available to you. Alerts are paused while you action your move.",
            },
            psComplete: {
              type: "text",
              description:
                "The accompanying text for the Get help button on the Property Page, used used for the SVR Management conversation if product selection has been completed.",
              default:
                "Thank you for selecting a new deal. Click below if you need more help.",
            },
          },
          internalExternalAdvice: {
            type: "text",
            description:
              "The accompanying text for the Get help button on the Property Page, used for the EOT Transactional conversations if a user selects internal advice or external advice.",
            default:
              "Thank you for selecting an option for proceeding to apply for a new rate. If you need help, click below.",
          },
          noReminder: {
            type: "text",
            description:
              "The accompanying text for the Get help button on the Property Page, used for the EOT Educational conversation once the user has expressed intent to not be reminded when products become available.",
            default:
              "You have asked not to be reminded when new rates become available. If you need help, click below.",
          },
          reminder: {
            type: "text",
            description:
              "The accompanying text for the Get help button on the Property Page, used for the EOT Educational conversation once the user has expressed intent to be reminded when products become available",
            default:
              "We will be in touch when you're able to see new rates. If you need help in the meantime, click below.",
          },
        },
        button: {
          review: {
            type: "mdx",
            description: `Text for the 'Review mortgages' button on the Property Page for Conversations. {props.mortgagePlural} will be "mortgage" if there is only one mortgage and "mortgages" otherwise.`,
            default: "Review {props.mortgagePlural}",
          },
          startReview: {
            type: "text",
            description:
              "The 'Start review' button that starts the Educational Understanding flow in Conversations, for when users haven't started the learning flow yet",
            default: "Start review",
          },
          continueReview: {
            type: "text",
            description:
              "The 'Continue review' button that continues the Educational Understanding flow in Conversations, for when users have started the learning flow",
            default: "Continue review",
          },
          seeOptions: {
            type: "text",
            description:
              "The 'See options' button that leads to the Options page in Conversations",
            default: "See options",
          },
          getHelp: {
            type: "text",
            description:
              "The 'Get help' button that leads to the Help page in Conversations",
            default: "Get help",
          },
        },
      },
      modal: {
        monthlyPayment: {
          heading: {
            type: "text",
            description:
              "Heading at the top of the Property Monthly Payment Modal for Conversations",
            default: "Monthly payment",
          },
          body: {
            type: "mdx",
            description:
              "The body of the Property Monthly Payment Modal for Conversations. {props.monthlyPayment} is the monthly payment for the property.",
            default: `You have a monthly payment of {props.monthlyPayment} for your property.`,
          },
        },
        loanBalance: {
          heading: {
            type: "text",
            description:
              "Heading at the top of the Property Loan Balance Modal for Conversations",
            default: "Loan balance",
          },
          body: {
            type: "mdx",
            description:
              "The body of the Property Loan Balance Modal for Conversations. {props.loanBalance} is the loan balance for the property.",
            default: `You have a loan balance of {props.loanBalance} for your property. This is the outstanding amount you have left to pay.`,
          },
        },
      },
    },
    understanding: {
      heading: {
        rateEnd: {
          type: "text",
          description:
            "Heading at the top of the first page of the understanding workflow, of the EOT Transactional Conversation",
          default: "Your rate end",
        },
        variableRate: {
          type: "text",
          description:
            "Heading at the top of the first page of the understanding workflow, of the SVR Management Conversation",
          default: "Standard variable rate",
        },
        monthlyPayments: {
          type: "text",
          description:
            "Heading at the top of all the pages of the understanding workflow, other than the first page",
          default: "Your monthly payments",
        },
      },
      modal: {
        completeLearning: {
          heading: {
            type: "text",
            description:
              "Heading at the top of the Complete Learning Modal for Conversations",
            default: "Complete your learning",
          },
          message: {
            type: "text",
            description: "The message in the Complete Learning Modal for Conversations",
            default:
              "You haven’t completed all of your learning topics, we’d recommend you review these before seeing your options.",
          },
          reviewButton: {
            type: "text",
            description:
              "The 'Review' button in the Complete Learning Modal for Conversations",
            default: "Review",
          },
          optionsButton: {
            type: "text",
            description:
              "The 'See options' button in the Complete Learning Modal for Conversations",
            default: "See options",
          },
        },
        eotIntent: {
          heading: {
            type: "text",
            description: "The heading of the EOT Intent Modal for Conversations.",
            default: "Would you like to move to a new deal?",
          },
          yesButton: {
            type: "text",
            description:
              "The 'Yes, view options' button in the EOT Intent Modal for Conversations",
            default: "Yes, view options",
          },
          noButton: {
            type: "text",
            description:
              "The 'No, I'm not interested' button in the EOT Intent Modal for Conversations",
            default: "No, I’m not interested",
          },
        },
        comeOffSvr: {
          heading: {
            type: "text",
            description:
              "Heading at the top of the Come Off SVR Modal for Conversations",
            default: "Would you like to come off the variable rate?",
          },
          yesButton: {
            type: "text",
            description: "The 'Yes' button in the Come Off SVR Modal for Conversations",
            default: "Yes, come off",
          },
          noButton: {
            type: "text",
            description: "The 'No' button in the Come Off SVR Modal for Conversations",
            default: "No, stay on",
          },
        },
      },
      understandButton: {
        type: "text",
        description:
          "Text for the 'I understand' button on the final Understanding Page for Conversations",
        default: "I understand",
      },
    },
    svrKeyFacts: {
      cards: {
        higherRate: {
          heading: {
            type: "text",
            description:
              "Heading at the top of the Higher Rate Card for the SVR Key Facts page in Conversations",
            default: "Higher rate",
          },
          body: {
            type: "text",
            description:
              "The body of the Higher Rate Card for the SVR Key Facts page in Conversations",
            default: "Typically our highest rate",
          },
        },
        variableRate: {
          heading: {
            type: "text",
            description:
              "Heading at the top of the Variable Rate Card for the SVR Key Facts page in Conversations",
            default: "Variable rate",
          },
          body: {
            type: "text",
            description:
              "The body of the Variable Rate Card for the SVR Key Facts page in Conversations",
            default: "It can go up or down at any time",
          },
        },
      },
      modal: {
        highestRate: {
          heading: {
            type: "text",
            description:
              "Heading at the top of the Highest Rate Modal for the SVR Key Facts page in Conversations",
            default: "Highest rate",
          },
          body: {
            type: "mdx",
            description:
              "The body of the Highest Rate Modal for the SVR Key Facts page in Conversations",
            default: `Our standard variable rate is our highest rate. This means you are paying the highest amount of interest on your loan. You can secure a lower rate by switching product.`,
          },
        },
        variableRate: {
          heading: {
            type: "text",
            description:
              "Heading at the top of the Variable Rate Modal for the SVR Key Facts page in Conversations",
            default: "Variable rate",
          },
          body: {
            type: "mdx",
            description:
              "The body of the Variable Rate Modal for the SVR Key Facts page in Conversations. {props.lender} is the name of the Lender firm.",
            default: `Our standard variable rate can go up or down at any time meaning your monthly payment could change as a result. The rate is set by {props.lender} and doesn’t necessarily follow any changes to the Bank of England’s base rate.`,
          },
        },
      },
    },
    svrComparison: {
      managedRate: {
        heading: {
          type: "text",
          description:
            "The heading at the top of the Managed Rate card, which appears at the bottom of the <SVRComparison /> component when there are multiple mortgages",
          default: "Managed rate",
        },
      },
      modal: {
        initialRate: {
          fixed: {
            heading: {
              type: "text",
              description:
                "Heading at the top of the Fixed Scheme Initial Rate Modal for for the SVR Comparison page (SVR Understanding 2) in Conversations",
              default: "Interest rate",
            },
            body: {
              type: "mdx",
              description:
                "The body of the Fixed Scheme Initial Rate Modal for the SVR Comparison page (SVR Understanding 2) in Conversations. {props.interestRate} is the interest rate. {props.expiryDate} is the date the initial term ended",
              default: `Your initial term interest rate was a fixed rate of {props.interestRate}. This rate ended on {props.expiryDate} when your initial term ended.`,
            },
          },
          variable: {
            heading: {
              type: "text",
              description:
                "Heading at the top of the Variable Scheme Initial Rate Modal for the SVR Comparison page (SVR Understanding 2) in Conversations",
              default: "Interest rate",
            },
            body: {
              type: "mdx",
              description:
                "The body of the Variable Scheme Initial Rate Modal for the SVR Comparison page (SVR Understanding 2) in Conversations. {props.interestRate} is the interest rate. {props.expiryDate} is the date the initial term ended",
              default: `Your initial term’s interest rate was a variable rate. You last paid a rate of {props.interestRate} on {props.expiryDate} when your initial term ended.`,
            },
          },
        },
        remainingRate: {
          single: {
            heading: {
              type: "text",
              description:
                "Heading at the top of the Single Mortgage Remaining Interest Rate Modal for the SVR Comparison page (SVR Understanding 2) in Conversations.",
              default: "Interest rate",
            },
            body: {
              type: "mdx",
              description:
                "The body of the Single Mortgage Remaining Interest Rate Modal for the SVR Comparison page (SVR Understanding 2) in Conversations. {props.interestRate} is the follow-on interest rate (SVR).",
              default: `Your remaining term interest rate is our standard variable rate, currently {props.interestRate}. This is a variable rate and can go up or down.`,
            },
          },
          multiple: {
            heading: {
              type: "text",
              description:
                "Heading at the top of the Multiple Mortgage Remaining Interest Rate Modal for the SVR Comparison page (SVR Understanding 2) in Conversations",
              default: "Interest rate",
            },
            body: {
              type: "mdx",
              description:
                "The body of the Multiple Mortgage Remaining Interest Rate Modal for the SVR Comparison page (SVR Understanding 2) in Conversations. Use <RemainingRatesTable /> to display the table listing the loan amounts and the follow-on rates.",
              default: `Your remaining term interest rate is as follows for your mortgages. <RemainingRatesTable />`,
            },
          },
        },
        interestVsCapital: {
          repayment: {
            heading: {
              type: "text",
              description:
                "Heading at the top of the Repayment Interest vs Capital Modal for the SVR Comparison page (SVR Understanding 2) in Conversations",
              default: "Interest vs capital",
            },
            body: {
              type: "mdx",
              description:
                "The body of the Repayment Interest vs Capital Modal for the SVR Comparison page (SVR Understanding 2) in Conversations",
              default: `On a repayment mortgage you pay back interest and capital with your monthly payment. Interest is the charge for borrowing the money and the capital is paying off your original loan amount.`,
            },
          },
          interestOnly: {
            heading: {
              type: "text",
              description:
                "Heading at the top of the Interest Only Interest vs Capital Modal for the SVR Comparison page (SVR Understanding 2) in Conversations",
              default: "Interest vs capital",
            },
            body: {
              type: "mdx",
              description:
                "The body of the Interest Only Interest vs Capital Modal for the SVR Comparison page (SVR Understanding 2) in Conversations",
              default: `On an interest-only mortgage you pay back interest each month which is the charge for borrowing the money. You do not pay any capital so the original loan amount remains the same and must be paid in full at the end of your mortgage term.`,
            },
          },
          partAndPart: {
            heading: {
              type: "text",
              description:
                "Heading at the top of the Part and Part Interest vs Capital Modal for the SVR Comparison page (SVR Understanding 2) in Conversations",
              default: "Interest vs capital",
            },
            body: {
              type: "mdx",
              description:
                "The body of the Part and Part Interest vs Capital Modal for the SVR Comparison page (SVR Understanding 2) in Conversations",
              default: `On your part and part mortgage your mortgage is split into two parts, repayment and interest only. On the repayment part you pay back interest and capital and with the interest-only you pay only interest. Interest is the charge for borrowing the money and the capital is paying off your original loan amount. You will be left with the loan amount for the interest-only part being owed at the end of the term.`,
            },
          },
          split: {
            heading: {
              type: "text",
              description:
                "Heading at the top of the Split Interest vs Capital Modal for the SVR Comparison page (SVR Understanding 2) in Conversations",
              default: "Interest vs capital",
            },
            body: {
              type: "mdx",
              description:
                "The body of the Split Interest vs Capital Modal for the SVR Comparison page (SVR Understanding 2) in Conversations",
              default: `On your split mortgage your mortgage is split into two parts, repayment and interest only. On the repayment part you pay back interest and capital and with the interest-only you pay only interest. Interest is the charge for borrowing the money and the capital is paying off your original loan amount. You will be left with the loan amount for the interest-only part being owed at the end of the term.`,
            },
          },
        },
      },
    },
    svrBreakdown: {
      summary: {
        single: {
          type: "text",
          description: `The summary paragraph for the Your mortgage payments SVR Breakdown page in Conversations for single mortgage.`,
          default:
            "The initial rate on your mortgage ends soon, your monthly payment will change when this happens.",
        },
        multiple: {
          type: "mdx",
          description: `The summary paragraph for the Your mortgage payments SVR Breakdown page in Conversations for multiple mortgages. {props.eotCount} is the number of mortgages that are on EOT.`,
          default:
            "You have {props.eotCount} mortgages where the initial rate ends soon, your monthly payments will change when this happens.",
        },
      },
      initial: {
        rateLabel: {
          type: "text",
          description:
            "The label / subheading that goes above the Current (Interest) Rate in the initial term card in Your mortgage payments SVR Breakdown page",
          default: "Current rate",
        },
        modal: {
          heading: {
            type: "text",
            description:
              "Heading at the top of the modal launched from the Initial Term panel in the SVRBreakdown MDX component",
            default: "Payment changes",
          },
          body: {
            type: "mdx",
            description:
              "The body of the the modal launched from the Initial Term panel in the SVRBreakdown MDX component. Has props.preferentialRate, props.startDate, props.followOnRate, props.followOnStartDate",
            default: `Your initial rate of {props.preferentialRate} started on {props.startDate}. When this ends, you move to our Managed Rate, currently {props.followOnRate}. Your mortgage payments from {props.followOnStartDate} are different as a result of this change.`,
          },
        },
      },
      remaining: {
        rateLabel: {
          type: "text",
          description:
            "The label / subheading that goes above the Variable (Interest) Rate in the remaining term card in Your mortgage payments SVR Breakdown page",
          default: "Variable rate",
        },
        modal: {
          heading: {
            type: "text",
            description:
              "Heading at the top of the modal launched from the Remaining Term panel in the SVRBreakdown MDX component",
            default: "Based on",
          },
          body: {
            type: "mdx",
            description:
              "The body of the the modal launched from the Remaining Term panel in the SVRBreakdown MDX component. Has props.givenCurrentBalance available.",
            default: `Your monthly payment is an estimated calculation using your current loan balance of {props.givenCurrentBalance} and remaining term. This payment will change in-line with any change to your current balance or our Managed Rate. The Managed Rate we use is as stated in your original mortgage offer. This rate is correct as of today and could change.`,
          },
        },
      },
    },
    options: {
      heading: {
        type: "text",
        description: "Heading at the top of the Options Page for Conversations",
        default: "Your options",
      },
      card: {
        withoutAdvice: {
          heading: {
            type: "text",
            description:
              "Heading at the top of the Without Advice Card on the Options Page for Conversations",
            default: "Start switch",
          },
          body: {
            type: "text",
            description:
              "The body of the Without Advice Card on the Options Page for Conversations",
            default:
              "Compare and select a product to apply for if you're happy to do so without advice or help",
          },
        },
        internalAdvice: {
          heading: {
            type: "text",
            description:
              "Heading at the top of the Internal Advice Card on the Options Page for Conversations",
            default: "Speak to an adviser",
          },
          body: {
            type: "text",
            description:
              "The body of the Internal Advice Card on the Options Page for Conversations",
            default:
              "Connect with one of our advisers to discuss and walk through our available rates and options",
          },
        },
        switchSupport: {
          heading: {
            type: "text",
            description:
              "Heading at the top of the Switch Support Card on the Options Page for Conversations",
            default: "Get help",
          },
          body: {
            type: "text",
            description:
              "The body of the Switch Support Card on the Options Page for Conversations",
            default:
              "Contact us about making changes such as borrowing more or changing term",
          },
        },
        externalAdvice: {
          heading: {
            type: "text",
            description:
              "Heading at the top of the External Advice Card on the Options Page for Conversations",
            default: "Speak to a broker",
          },
          body: {
            type: "text",
            description:
              "The body of the External Advice Card on the Options Page for Conversations",
            default:
              "Connect with an independent broker to get help with reviewing current rates and options",
          },
        },
      },
      modal: {
        remindMe: {
          heading: {
            type: "text",
            description: "The body of the Remind Me Modal for Conversations.",
            default: "Would you like to be reminded when products are available?",
          },
          yesButton: {
            type: "text",
            description:
              "The 'Yes, remind me' button in the Remind Me Modal for Conversations",
            default: "Yes, remind me",
          },
          noButton: {
            type: "text",
            description:
              "The 'No, I'm not interested' button in the Remind Me Modal for Conversations",
            default: "No, I’m not interested",
          },
        },
        why: {
          heading: {
            type: "text",
            description: `Heading at the top of the Why No Reminder Modal for Conversations, which shows if they click the "No, I'm not interested" button in the Remind Me Modal`,
            default: "Let us know why",
          },
          confirmButton: {
            type: "text",
            description:
              "The 'Confirm' button in the Why No Reminder Modal for Conversations",
            default: "Confirm",
          },
        },
      },
    },
    learn: {
      index: {
        heading: {
          type: "text",
          description: "Heading at the top of the Learn Index Page for Conversations",
          default: "Moving to a new rate",
        },
        intro: {
          type: "text",
          description:
            "The introductory text at the top of the Learn Index Page for Conversations",
          default:
            "Review your financial situation and goals against available mortgage features to move to a new rate. Click below to select a topic to learn about.",
        },
      },
    },
    thanks: {
      modal: {
        continueButton: {
          type: "text",
          description: "The 'Continue' button in the Thanks Modal for Conversations",
          default: "Continue",
        },
      },
    },
    terminology: {
      svr: {
        type: "text",
        description: "The term used to refer to the Standard Variable Rate",
        default: "Standard variable rate",
      },
    },
  },
} as const

export type ConversationLabelPaths = LabelPaths<typeof conversationBlocks>

export const replacesConversationBlocks: ReplacesList = [
  {
    src: "conversations.svrBreakdown.initial.heading",
    dest: "conversations.svrBreakdown.initial.modal.heading",
  },
  {
    src: "conversations.svrBreakdown.initial.body",
    dest: "conversations.svrBreakdown.initial.modal.body",
  },
  {
    src: "conversations.svrBreakdown.remaining.heading",
    dest: "conversations.svrBreakdown.remaining.modal.heading",
  },
  {
    src: "conversations.svrBreakdown.remaining.body",
    dest: "conversations.svrBreakdown.remaining.modal.body",
  },
]
